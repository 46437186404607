import PropTypes from "prop-types"
import React, { useState, useRef, useEffect } from "react"
import Scroll from 'react-scroll';
import styled from 'styled-components'

const ScrollLink = Scroll.Link;

const StyledScrollLink = styled(ScrollLink)`
  cursor: pointer;
`
const Header = ({ siteTitle, logoPath }) => {
  const [isSticky, setSticky] = useState(false);
  const [isMenuShowing, setMenuShowing] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    }
  }, []);

  const toggleMenu = () => {
    setMenuShowing(!isMenuShowing);
  }

  const hideMenu = () => {
    setMenuShowing(false);
  }

  const stickStyles = isSticky ? { position: 'fixed', top: '0' } : { position: 'relative' };
  const menuStyles = isMenuShowing ? { height: '400px' } : {}

  return (
    <header
      id="header"
      className="header-effect-shrink"
      style={{ position: 'relative', minHeight: '100px' }}
    >
      <div
        className="header-body"
        style={{ ...stickStyles, ...menuStyles }}
        ref={ref}
      >
        <div className="header-container container">
          <div className="header-row">
            <div className="header-column">
              <div className="header-row">
                <div className="header-logo">
                  <a href="/">
                    <img alt="Porto" data-sticky-width="82" data-sticky-height="40" src={logoPath} />
                  </a>
                </div>
              </div>
            </div>
            <div className="header-column justify-content-end">
              <div className="header-row">
                <div className="header-nav header-nav-links order-2 order-lg-1">
                  <div className="header-nav-main header-nav-main-square header-nav-main-effect-2 header-nav-main-sub-effect-1">
                    <nav className={isMenuShowing ? "collapse show" : "collapse"}>
                      <ul className="nav nav-pills" id="mainNav">
                        <li className="dropdown">
                          <StyledScrollLink
                            to="home"
                            spy={true}
                            smooth={true}
                            duration={500}
                            activeClass="active"
                            offset={-100}
                            onSetActive={hideMenu}
                            className="dropdown-item dropdown-toggle">Home</StyledScrollLink>
                        </li>
                        <li className="dropdown dropdown-mega">
                          <StyledScrollLink
                            to="aboutus"
                            spy={true}
                            smooth={true}
                            duration={500}
                            activeClass="active"
                            offset={-100}
                            onSetActive={hideMenu}
                            className="dropdown-item dropdown-toggle">About Us</StyledScrollLink>
                        </li>
                        <li className="dropdown">
                          <StyledScrollLink
                            to="ourservices"
                            spy={true}
                            smooth={true}
                            duration={500}
                            activeClass="active"
                            offset={-100}
                            onSetActive={hideMenu}
                            className="dropdown-item dropdown-toggle">Our Services</StyledScrollLink>
                        </li>
                        <li className="dropdown">
                          <StyledScrollLink
                            to="experience"
                            spy={true}
                            smooth={true}
                            duration={500}
                            activeClass="active"
                            offset={-100}
                            onSetActive={hideMenu}
                            className="dropdown-item dropdown-toggle">Experience</StyledScrollLink>
                        </li>
                        <li className="dropdown">
                          <StyledScrollLink
                            to="publications"
                            spy={true}
                            smooth={true}
                            duration={500}
                            activeClass="active"
                            offset={-100}
                            onSetActive={hideMenu}
                            className="dropdown-item dropdown-toggle">Publications</StyledScrollLink>
                        </li>
                        <li className="dropdown">
                          <StyledScrollLink
                            to="contactus"
                            spy={true}
                            smooth={true}
                            duration={500}
                            activeClass="active"
                            onSetActive={hideMenu}
                            // offset={-100}
                            className="dropdown-item dropdown-toggle">Get in Touch</StyledScrollLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <button
                    className="btn header-btn-collapse-nav"
                    data-toggle="collapse"
                    data-target=".header-nav-main nav"
                    onClick={toggleMenu}
                  >
                    <i className="fas fa-bars"></i>
                  </button>
                </div >
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
