import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

const Footer = ({ logoPath }) => {
    let _name, _emailAddress, _message, _subject, _submitButton;

    const submit = (e) => {
        e.preventDefault();
        let xhr = new XMLHttpRequest();
        xhr.addEventListener('load', () => {
            _name.value = ''
            _emailAddress.value = ''
            _message.value = ''
            _subject.value = ''
            _submitButton.value = 'SUBMIT'
        });

        xhr.open('POST', '/api/contactus');
        xhr.setRequestHeader('Content-Type', 'application/json');
        _submitButton.value = 'SENDING';
        xhr.send(JSON.stringify({
            name: _name.value,
            email: _emailAddress.value,
            message: _message.value,
            subject: _subject.value
        }));
    };

    return <React.Fragment>
        <Element id="contactus" name="contactus">
            <footer id="footer" className="mt-0">
                <div className="container">
                    <div className="footer-ribbon">
                        <span>Get in Touch</span>
                    </div>
                    <div className="row py-5 my-4">
                        <div className="col-md-7 col-lg-4 mb-5 mb-lg-0 pt-3">
                            <h5 className="text-3 mb-3">ABOUT US</h5>
                            <p>At PHSR Consulting, we provide you the best in class systematic reviews</p>
                        </div>
                        <div className="col-md-5 col-lg-3 mb-5 mb-lg-0 pt-3">
                            <h5 className="text-3 mb-3 pb-1">CONTACT US</h5>
                            <p className="text-8 text-color-light font-weight-bold">+44 (0) 7444-204018</p>
                            <ul className="list list-icons list-icons-lg">
                                <li className="mb-1"><i className="far fa-dot-circle text-color-primary"></i><p className="m-0">
                                    Hunt Blake LTD <br /> Jubilee House <br /> The Oaks, Ruislip <br /> HA4 7LF <br /> UK </p></li>
                                <li className="mb-1"><i className="far fa-envelope text-color-primary"></i><p className="m-0"><a href="mailto:mail@example.com">info@phsrconsulting.com</a></p></li>
                            </ul>
                            <ul className="social-icons mt-4">
                                <li className="social-icons-twitter"><a href="http://www.twitter.com/" target="_blank" rel="noopener noreferrer" title="Twitter"><i className="fab fa-twitter"></i></a></li>
                                <li className="social-icons-linkedin"><a href="http://www.linkedin.com/" target="_blank" rel="noopener noreferrer" title="Linkedin"><i className="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                        <div id="contact-us" className="col-lg-5 pt-3">
                            <h5 className="text-3 mb-3 pb-1">SEND US A MESSAGE</h5>
                            <form className="contact-form" onSubmit={submit}>
                                <input type="hidden" value="Contact Form" name="subject" id="subject" ref={(input) => (_subject = input)} />
                                <div className="contact-form-success alert alert-success d-none">Message has been sent to us.</div>
                                <div className="contact-form-error alert alert-danger d-none">
                                    Error sending your message.
								<span className="mail-error-message text-1 d-block"></span>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col">
                                        <input
                                            ref={(input) => (_name = input)}
                                            type="text"
                                            data-msg-required="Please enter your name."
                                            maxLength="100"
                                            className="form-control py-2"
                                            placeholder="Your Name..."
                                            name="name"
                                            id="name"
                                            required />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input
                                        ref={(input) => (_emailAddress = input)}
                                        type="email"
                                        data-msg-required="Please enter your email address."
                                        data-msg-email="Please enter a valid email address."
                                        maxLength="100"
                                        className="form-control py-2"
                                        placeholder="Your Email Address..."
                                        name="email"
                                        id="email"
                                        required />
                                </div>
                                <div className="form-row">
                                    <div className="form-group col">
                                        <textarea
                                            ref={(input) => (_message = input)}
                                            maxLength="5000"
                                            data-msg-required="Please enter your message."
                                            rows="4"
                                            className="form-control"
                                            placeholder="Your Message..."
                                            name="message"
                                            id="message"
                                            required>
                                        </textarea>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col">
                                        <input
                                            ref={(input) => (_submitButton = input)}
                                            type="submit"
                                            value="SUBMIT"
                                            className="btn btn-xl btn-outline btn-primary text-1 font-weight-bold text-uppercase"
                                            data-loading-text="Loading..."
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">
                    <div className="container py-2">
                        <div className="row py-4">
                            <div className="col-lg-1 d-flex align-items-center justify-content-center justify-content-lg-start mb-2 mb-lg-0">
                                <div className="logo pr-0 pr-lg-3">
                                    <img alt="PHSR Consulting Ltd" src={logoPath} className="opacity-5" height="33" />
                                </div>
                            </div>
                            <div className="col-lg-7 d-flex align-items-center justify-content-center justify-content-lg-start mb-4 mb-lg-0">
                                <p>© Copyright {new Date().getFullYear()}. All Rights Reserved.</p>
                            </div>
                            {/* <div className="col-lg-4 d-flex align-items-center justify-content-center justify-content-lg-end">
                            <nav id="sub-menu">
                                <ul>
                                    <li><i className="fas fa-angle-right"></i><a href="page-faq.html" className="ml-1 text-decoration-none"> FAQ's</a></li>
                                    <li><i className="fas fa-angle-right"></i><a href="sitemap.html" className="ml-1 text-decoration-none"> Sitemap</a></li>
                                    <li><i className="fas fa-angle-right"></i><a href="contact-us.html" className="ml-1 text-decoration-none"> Contact Us</a></li>
                                </ul>
                            </nav>
                        </div> */}
                        </div>
                    </div>
                </div>
            </footer>
        </Element>
    </React.Fragment>
};

Footer.protoTypes = {
    logoPath: PropTypes.string.isRequired
}

Footer.defaultProps = {
    logoPath: ''
}

export default Footer;