/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
// import "./layout.css"
import Footer from "./footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
  {
    site: site {
      siteMetadata {
        title
      }
    }
    logo: allFile(filter: {sourceInstanceName: {eq: "images"}, relativePath: {eq: "logo.png"}}, limit: 1) {
      nodes {
        dir
        relativeDirectory
        relativePath
        publicURL
        childImageSharp {
          resize(height: 138, width: 190, pngQuality: 10, fit: INSIDE) {
            src
          }
        }
      }
    }
  }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} logoPath={data.logo.nodes[0].childImageSharp.resize.src} />
      <main className="main">{children}</main>
      <Footer logoPath={data.logo.nodes[0].publicURL} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
